import React from 'react'
import PropTypes from 'prop-types'
import { Message, PinMessage, PinnedMessageContainer, Username, ModeratorIcon } from '../style'
import MessageWithLink from './MessageWithLink'
import PushPinIcon from '@mui/icons-material/PushPin'

export const PinnedMessage = ({ isDesktop, message }) => {
  return (
    <PinMessage isDesktop={isDesktop}>
      <PinnedMessageContainer>
        <span>
          <ModeratorIcon isPin />
        </span>
        {isDesktop ? (
          <PushPinIcon fontSize="inherit" />
        ) : (
          <PushPinIcon fontSize="inherit" style={{ color: 'white' }} />
        )}
        <Username isDesktop={isDesktop} data-test="username-inner-chat">
          {message.username}:
        </Username>
        <MessageWithLink view="Chat" logType="click-url-in-chat" isDesktop={isDesktop}>
          <Message isDesktop={isDesktop} data-test="user-message">
            {message.text}
          </Message>
        </MessageWithLink>
      </PinnedMessageContainer>
    </PinMessage>
  )
}

PinnedMessage.propTypes = {
  isDesktop: PropTypes.bool,
  message: PropTypes.object.isRequired,
}
