export const badWords = [
  '2g1c',
  '4r5e',
  '5h1t',
  '5hit',
  'Goddamn',
  'LEN',
  'a$$',
  'a$$hole',
  'a2m',
  'a55',
  'a55hole',
  'a_s_s',
  'acrotomophilia',
  'adult',
  'aeolus',
  'ahole',
  'amateur',
  'anal',
  'analprobe',
  'anilingus',
  'anus',
  'apeshit',
  'ar5e',
  'areola',
  'areole',
  'arian',
  'arrse',
  'arse',
  'arsehole',
  'aryan',
  'ass',
  'assbang',
  'assbanged',
  'assbangs',
  'asses',
  'assfukka',
  'assh0le',
  'asshat',
  'assho1e',
  'asshole',
  'assholes',
  'assmaster',
  'assmucus',
  'assmunch',
  'asswhole',
  'asswipe',
  'asswipes',
  'autoerotic',
  'azazel',
  'azz',
  'b!tch',
  'b00bs',
  'b17ch',
  'b1tch',
  'babe',
  'babeland',
  'babes',
  'ballbag',
  'balls',
  'ballsack',
  'bang',
  'bangbros',
  'banger',
  'bareback',
  'barenaked',
  'barf',
  'bastard',
  'bastardo',
  'bastards',
  'bastinado',
  'bawdy',
  'bbw',
  'bdsm',
  'beaner',
  'beaners',
  'beardedclam',
  'beastial',
  'beastiality',
  'beatch',
  'beeyotch',
  'bellend',
  'beotch',
  'bestial',
  'bestiality',
  'bi+ch',
  'biatch',
  'bigtits',
  'bimbo',
  'bimbos',
  'birdlock',
  'bitch',
  'bitched',
  'bitcher',
  'bitchers',
  'bitches',
  'bitchin',
  'bitching',
  'bitchy',
  'bloody',
  'blow',
  'blowjob',
  'blowjobs',
  'blumpkin',
  'bod',
  'bodily',
  'boink',
  'boiolas',
  'bollock',
  'bollocks',
  'bollok',
  'bondage',
  'bone',
  'boned',
  'boner',
  'boners',
  'bong',
  'boob',
  'boobies',
  'boobs',
  'booby',
  'booger',
  'bookie',
  'booobs',
  'boooobs',
  'booooobs',
  'booooooobs',
  'bootee',
  'bootie',
  'booty',
  'booze',
  'boozer',
  'boozy',
  'bosom',
  'bosomy',
  'bowel',
  'bowels',
  'bra',
  'brassiere',
  'breast',
  'breasts',
  'buceta',
  'bugger',
  'bukkake',
  'bulldyke',
  'bullshit',
  'bullshits',
  'bullshitted',
  'bullturds',
  'bum',
  'bung',
  'bunghole',
  'busty',
  'butt',
  'buttcheeks',
  'buttfuck',
  'buttfucker',
  'butthole',
  'buttmuch',
  'buttmunch',
  'buttplug',
  'c0ck',
  'c0cksucker',
  'caca',
  'cahone',
  'cameltoe',
  'camgirl',
  'camslut',
  'camwhore',
  'carpetmuncher',
  'cawk',
  'cervix',
  'chinc',
  'chincs',
  'chink',
  'choade',
  'chode',
  'chodes',
  'cipa',
  'circlejerk',
  'cl1t',
  'climax',
  'clit',
  'clitoris',
  'clitorus',
  'clits',
  'clitty',
  'clusterfuck',
  'cnut',
  'cocain',
  'cocaine',
  'cock',
  'cockblock',
  'cockface',
  'cockhead',
  'cockholster',
  'cockknocker',
  'cockmunch',
  'cockmuncher',
  'cocks',
  'cocksmoker',
  'cocksuck',
  'cocksucked',
  'cocksucker',
  'cocksucking',
  'cocksucks',
  'cocksuka',
  'cocksukka',
  'coital',
  'cok',
  'cokmuncher',
  'coksucka',
  'commie',
  'condom',
  'coon',
  'coons',
  'coprolagnia',
  'coprophilia',
  'corksucker',
  'cornhole',
  'cox',
  'crabs',
  'crack',
  'cracker',
  'crackwhore',
  'crap',
  'crappy',
  'creampie',
  'cum',
  'cumdump',
  'cummer',
  'cummin',
  'cumming',
  'cums',
  'cumshot',
  'cumshots',
  'cumslut',
  'cumstain',
  'cunilingus',
  'cunillingus',
  'cunnilingus',
  'cunny',
  'cunt',
  'cuntbag',
  'cuntface',
  'cunthunter',
  'cuntlick',
  'cuntlicker',
  'cuntlicking',
  'cunts',
  'cuntsicle',
  'cyalis',
  'cyberfuc',
  'cyberfuck',
  'cyberfucked',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking',
  'd0ng',
  'd0uch3',
  'd0uche',
  'd1ck',
  'd1ld0',
  'd1ldo',
  'dago',
  'dagos',
  'dammit',
  'damn',
  'damned',
  'damnit',
  'darkie',
  'daterape',
  'deepthroat',
  'dendrophilia',
  'dick',
  'dickbag',
  'dickdipper',
  'dickface',
  'dickflipper',
  'dickhead',
  'dickheads',
  'dickish',
  'dickripper',
  'dicksipper',
  'dickweed',
  'dickwhipper',
  'dickzipper',
  'diddle',
  'dike',
  'dildo',
  'dildos',
  'diligaf',
  'dillweed',
  'dimwit',
  'dingle',
  'dingleberries',
  'dingleberry',
  'dink',
  'dinks',
  'dipship',
  'dirsa',
  'dlck',
  'doggiestyle',
  'doggin',
  'dogging',
  'doggystyle',
  'dolcett',
  'domination',
  'dominatrix',
  'dommes',
  'dong',
  'donkeyribber',
  'doofus',
  'doosh',
  'dopey',
  'douch3',
  'douche',
  'douchebag',
  'douchebags',
  'douchey',
  'drunk',
  'duche',
  'dumass',
  'dumbass',
  'dumbasses',
  'dummy',
  'dvda',
  'dyke',
  'dykes',
  'ecchi',
  'ejaculate',
  'ejaculated',
  'ejaculates',
  'ejaculating',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'enlargement',
  'erect',
  'erection',
  'erotic',
  'erotism',
  'escort',
  'essohbee',
  'eunuch',
  'extacy',
  'extasy',
  'f4nny',
  'f_u_c_k',
  'facial',
  'fack',
  'fag',
  'fagg',
  'fagged',
  'fagging',
  'faggit',
  'faggitt',
  'faggot',
  'faggs',
  'fagot',
  'fagots',
  'fags',
  'faig',
  'faigt',
  'fanny',
  'fannybandit',
  'fannyflaps',
  'fannyfucker',
  'fanyy',
  'fart',
  'fartknocker',
  'fat',
  'fatass',
  'fcuk',
  'fcuker',
  'fcuking',
  'fecal',
  'feck',
  'fecker',
  'felch',
  'felcher',
  'felching',
  'fellate',
  'fellatio',
  'feltch',
  'feltcher',
  'femdom',
  'figging',
  'fingerbang',
  'fingering',
  'fisted',
  'fisting',
  'fisty',
  'flange',
  'floozy',
  'foad',
  'fondle',
  'foobar',
  'fook',
  'fooker',
  'footjob',
  'foreskin',
  'freex',
  'frigg',
  'frigga',
  'frotting',
  'fubar',
  'fuck',
  'fucka',
  'fuckass',
  'fucked',
  'fucker',
  'fuckers',
  'fuckface',
  'fuckhead',
  'fuckheads',
  'fuckin',
  'fucking',
  'fuckings',
  'fuckingshitmotherfucker',
  'fuckme',
  'fuckmeat',
  'fucknugget',
  'fucknut',
  'fuckoff',
  'fucks',
  'fucktard',
  'fucktards',
  'fucktoy',
  'fuckup',
  'fuckwad',
  'fuckwhit',
  'fuckwit',
  'fudgepacker',
  'fuk',
  'fuker',
  'fukker',
  'fukkin',
  'fuks',
  'fukwhit',
  'fukwit',
  'futanari',
  'fux',
  'fux0r',
  'fvck',
  'fxck',
  'gae',
  'gai',
  'gangbang',
  'gangbanged',
  'gangbangs',
  'ganja',
  'gay',
  'gaylord',
  'gays',
  'gaysex',
  'genitals',
  'gey',
  'gfy',
  'ghay',
  'ghey',
  'gigolo',
  'glans',
  'goatcx',
  'goatse',
  'godamn',
  'godamnit',
  'goddam',
  'goddammit',
  'goddamn',
  'goddamned',
  'gokkun',
  'goldenshower',
  'gonad',
  'gonads',
  'goodpoop',
  'gook',
  'gooks',
  'goregasm',
  'gringo',
  'grope',
  'gspot',
  'gtfo',
  'guido',
  'guro',
  'h0m0',
  'h0mo',
  'handjob',
  'hardcore',
  'hardcoresex',
  'he11',
  'hebe',
  'heeb',
  'hell',
  'hemp',
  'hentai',
  'heroin',
  'herp',
  'herpes',
  'herpy',
  'heshe',
  'hiv',
  'hoar',
  'hoare',
  'hobag',
  'hoer',
  'hom0',
  'homey',
  'homo',
  'homoerotic',
  'homoey',
  'honkey',
  'honky',
  'hooch',
  'hookah',
  'hooker',
  'hoor',
  'hootch',
  'hooter',
  'hooters',
  'hore',
  'horniest',
  'horny',
  'hotsex',
  'hump',
  'humped',
  'humping',
  'hussy',
  'hymen',
  'inbred',
  'incest',
  'injun',
  'intercourse',
  'j3rk0ff',
  'jackass',
  'jackhole',
  'jackoff',
  'jailbait',
  'jap',
  'japs',
  'jerk',
  'jerk0ff',
  'jerked',
  'jerkoff',
  'jigaboo',
  'jiggaboo',
  'jiggerboo',
  'jism',
  'jiz',
  'jizm',
  'jizz',
  'jizzed',
  'juggs',
  'junkie',
  'junky',
  'kawk',
  'kike',
  'kikes',
  'kill',
  'kinbaku',
  'kinkster',
  'kinky',
  'kkk',
  'klan',
  'knob',
  'knobbing',
  'knobead',
  'knobed',
  'knobend',
  'knobhead',
  'knobjocky',
  'knobjokey',
  'kock',
  'kondum',
  'kondums',
  'kooch',
  'kooches',
  'kootch',
  'kraut',
  'kum',
  'kummer',
  'kumming',
  'kums',
  'kunilingus',
  'kwif',
  'kyke',
  'l3i+ch',
  'l3itch',
  'labia',
  'lech',
  'leper',
  'lesbians',
  'lesbo',
  'lesbos',
  'lez',
  'lezbian',
  'lezbians',
  'lezbo',
  'lezbos',
  'lezzie',
  'lezzies',
  'lezzy',
  'lmao',
  'lmfao',
  'loin',
  'loins',
  'lolita',
  'lovemaking',
  'lube',
  'lust',
  'lusting',
  'lusty',
  'm0f0',
  'm0fo',
  'm45terbate',
  'ma5terb8',
  'ma5terbate',
  'mafugly',
  'mams',
  'masochist',
  'massa',
  'masterb8',
  'masterbat*',
  'masterbat3',
  'masterbate',
  'masterbating',
  'masterbation',
  'masterbations',
  'masturbate',
  'masturbating',
  'masturbation',
  'maxi',
  'menses',
  'menstruate',
  'menstruation',
  'meth',
  'milf',
  'mof0',
  'mofo',
  'molest',
  'moolie',
  'moron',
  'mothafuck',
  'mothafucka',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking',
  'mothafuckings',
  'mothafucks',
  'motherfuck',
  'motherfucka',
  'motherfucked',
  'motherfucker',
  'motherfuckers',
  'motherfuckin',
  'motherfucking',
  'motherfuckings',
  'motherfuckka',
  'motherfucks',
  'mtherfucker',
  'mthrfucker',
  'mthrfucking',
  'muff',
  'muffdiver',
  'muffdiving',
  'murder',
  'mutha',
  'muthafecker',
  'muthafuckaz',
  'muthafucker',
  'muthafuckker',
  'muther',
  'mutherfucker',
  'mutherfucking',
  'muthrfucking',
  'n1gga',
  'n1gger',
  'nad',
  'nads',
  'naked',
  'nambla',
  'napalm',
  'nappy',
  'nawashi',
  'nazi',
  'nazism',
  'negro',
  'neonazi',
  'nigg3r',
  'nigg4h',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'niggers',
  'niggle',
  'niglet',
  'nimphomania',
  'nimrod',
  'ninny',
  'nipple',
  'nipples',
  'nob',
  'nobhead',
  'nobjocky',
  'nobjokey',
  'nooky',
  'nude',
  'nudity',
  'numbnuts',
  'nutsack',
  'nympho',
  'nymphomania',
  'octopussy',
  'omorashi',
  'opiate',
  'opium',
  'oral',
  'orally',
  'organ',
  'orgasim',
  'orgasims',
  'orgasm',
  'orgasmic',
  'orgasms',
  'orgies',
  'orgy',
  'ovary',
  'ovum',
  'ovums',
  'p0rn',
  'paddy',
  'paedophile',
  'paki',
  'pantie',
  'panties',
  'panty',
  'pastie',
  'pasty',
  'pawn',
  'pcp',
  'pecker',
  'pedo',
  'pedobear',
  'pedophile',
  'pedophilia',
  'pedophiliac',
  'pee',
  'peepee',
  'pegging',
  'penetrate',
  'penetration',
  'penial',
  'penile',
  'penis',
  'penisfucker',
  'perversion',
  'peyote',
  'phalli',
  'phallic',
  'phonesex',
  'phuck',
  'phuk',
  'phuked',
  'phuking',
  'phukked',
  'phukking',
  'phuks',
  'phuq',
  'pigfucker',
  'pillowbiter',
  'pimp',
  'pimpis',
  'pinko',
  'piss',
  'pissed',
  'pisser',
  'pissers',
  'pisses',
  'pissflaps',
  'pissin',
  'pissing',
  'pissoff',
  'pisspig',
  'playboy',
  'pms',
  'polack',
  'pollock',
  'ponyplay',
  'poof',
  'poon',
  'poontang',
  'poop',
  'poopchute',
  'porn',
  'porno',
  'pornography',
  'pornos',
  'pot',
  'potty',
  'prick',
  'pricks',
  'prig',
  'pron',
  'prostitute',
  'prude',
  'pthc',
  'pube',
  'pubes',
  'pubic',
  'pubis',
  'punany',
  'punkass',
  'punky',
  'puss',
  'pusse',
  'pussi',
  'pussies',
  'pussy',
  'pussypounder',
  'pussys',
  'puto',
  'queaf',
  'queef',
  'queer',
  'queero',
  'queers',
  'quicky',
  'quim',
  'racy',
  'raghead',
  'rape',
  'raped',
  'raper',
  'raping',
  'rapist',
  'raunch',
  'rectal',
  'rectum',
  'rectus',
  'reefer',
  'reetard',
  'reich',
  'retard',
  'retarded',
  'revue',
  'rimjaw',
  'rimjob',
  'rimming',
  'ritard',
  'rtard',
  'rum',
  'rump',
  'rumprammer',
  'ruski',
  's&m',
  's0b',
  's_h_i_t',
  'sadism',
  'sadist',
  'sandbar',
  'santorum',
  'scag',
  'scantily',
  'scat',
  'schizo',
  'schlong',
  'scissoring',
  'screw',
  'screwed',
  'screwing',
  'scroat',
  'scrog',
  'scrot',
  'scrote',
  'scrotum',
  'scrud',
  'scum',
  'seaman',
  'seamen',
  'seduce',
  'semen',
  'sex',
  'sexo',
  'sexual',
  'sexy',
  'sh!+',
  'sh!t',
  'sh1t',
  'shag',
  'shagger',
  'shaggin',
  'shagging',
  'shagging',
  'shamedame',
  'shemale',
  'shi+',
  'shibari',
  'shit',
  'shitblimp',
  'shitdick',
  'shite',
  'shiteater',
  'shited',
  'shitey',
  'shitface',
  'shitfuck',
  'shitfull',
  'shithead',
  'shithole',
  'shithouse',
  'shiting',
  'shitings',
  'shits',
  'shitt',
  'shitted',
  'shitter',
  'shitters',
  'shitting',
  'shittings',
  'shitty',
  'shiz',
  'shota',
  'shrimping',
  'sissy',
  'skag',
  'skank',
  'skeet',
  'slanteye',
  'slave',
  'sleaze',
  'sleazy',
  'slope',
  'slut',
  'slutdumper',
  'slutkiss',
  'sluts',
  'smegma',
  'smut',
  'smutty',
  'snatch',
  'sniper',
  'snowballing',
  'snuff',
  'sodom',
  'sodomize',
  'sodomy',
  'souse',
  'soused',
  'spac',
  'sperm',
  'spic',
  'spick',
  'spik',
  'spiks',
  'splooge',
  'spooge',
  'spunk',
  'steamy',
  'stfu',
  'stiffy',
  'stoned',
  'strapon',
  'strappado',
  'strip',
  'stroke',
  'stupid',
  'suck',
  'sucked',
  'sucking',
  'sucks',
  'sumofabiatch',
  'swastika',
  'swinger',
  't1t',
  't1tt1e5',
  't1tties',
  'tampon',
  'tard',
  'tawdry',
  'teabagging',
  'teat',
  'teets',
  'teez',
  'terd',
  'teste',
  'testee',
  'testes',
  'testical',
  'testicle',
  'testis',
  'threesome',
  'throating',
  'thrust',
  'thug',
  'tinkle',
  'tit',
  'titfuck',
  'titi',
  'tits',
  'titt',
  'tittie5',
  'tittiefucker',
  'titties',
  'titty',
  'tittyfuck',
  'tittyfucker',
  'tittywank',
  'titwank',
  'toke',
  'toots',
  'topless',
  'tosser',
  'towelhead',
  'tramp',
  'tranny',
  'transsexual',
  'trashy',
  'tribadism',
  'tubgirl',
  'turd',
  'tush',
  'tushy',
  'tw4t',
  'twat',
  'twathead',
  'twats',
  'twatty',
  'twink',
  'twinkie',
  'twunt',
  'twunter',
  'ugly',
  'undies',
  'undressing',
  'unwed',
  'upskirt',
  'urinal',
  'urine',
  'urophilia',
  'uterus',
  'uzi',
  'v14gra',
  'v1gra',
  'vag',
  'vagina',
  'valium',
  'viagra',
  'vibrator',
  'virgin',
  'vixen',
  'vodka',
  'vomit',
  'vorarephilia',
  'voyeur',
  'vulgar',
  'vulva',
  'w00se',
  'wad',
  'wang',
  'wank',
  'wanker',
  'wanky',
  'wazoo',
  'wedgie',
  'weed',
  'weenie',
  'weewee',
  'weiner',
  'weirdo',
  'wench',
  'wetback',
  'wh0re',
  'wh0reface',
  'whitey',
  'whiz',
  'whoar',
  'whoralicious',
  'whore',
  'whorealicious',
  'whored',
  'whoreface',
  'whorehopper',
  'whorehouse',
  'whores',
  'whoring',
  'wigger',
  'willies',
  'willy',
  'womb',
  'woody',
  'wop',
  'wtf',
  'xx',
  'xxx',
  'yaoi',
  'yeasty',
  'yiffy',
  'yobbo',
  'zoophile',
  'zoophilia',
  '🖕',
  'Blocked words',
  'anal',
  'anus',
  'arse',
  'ass',
  'balls',
  'ballsack',
  'bastard',
  'biatch',
  'bitch',
  'bloody',
  'blow',
  'blowjob',
  'bollock',
  'bollok',
  'boner',
  'boob',
  'bugger',
  'bum',
  'butt',
  'buttplug',
  'clitoris',
  'cock',
  'coon',
  'crap',
  'cunt',
  'damn',
  'dick',
  'dildo',
  'dyke',
  'fag',
  'feck',
  'felching',
  'fellate',
  'fellatio',
  'flange',
  'fuck',
  'fudgepacker',
  'Goddamn',
  'homo',
  'jerk',
  'jizz',
  'knob',
  'knobend',
  'muff',
  'nigga',
  'nigger',
  'penis',
  'piss',
  'poop',
  'prick',
  'pube',
  'pussy',
  'queer',
  'scrotum',
  'sex',
  'sh1t',
  'shit',
  'slut',
  'smegma',
  'spunk',
  'tit',
  'tosser',
  'turd',
  'twat',
  'vagina',
  'wank',
  'whore',
  'asno',
  'bastardo',
  'Cabron',
  'Caca',
  'Chupada',
  'Chupapollas',
  'Concha',
  'Coño',
  'Coprofagía',
  'Culo',
  'Drogas',
  'Esperma',
  'Follador',
  'Follar',
  'Gilipichis',
  'Gilipollas',
  'concha',
  'Heroína',
  'Hijaputa',
  'Hijoputa',
  'Idiota',
  'Imbécil',
  'infierno',
  'Jilipollas',
  'Kapullo',
  'Lameculos',
  'boludo',
  'pelotudo',
  'maldito',
  'Mamada',
  'Marica',
  'Maricón',
  'Mariconazo',
  'martillo',
  'Mierda',
  'Orina',
  'Paja',
  'Pedo',
  'pene',
  'Pezón',
  'Pinche',
  'Prostituta',
  'Puta',
  'puto',
  'Ramera',
  'Semen',
  'Sexo',
  'Soplagaitas',
  'Soplapollas',
  'tetas',
  'Trio',
  'Verga',
  'vulva',
  'abortera',
  'alto puto',
  'amargos',
  'anal',
  'analfabeto',
  'averga',
  'awebonado',
  'aweonao',
  'becerro',
  'bobo',
  'bolita',
  'boludita',
  'boludito',
  'brazuca',
  'buchón',
  'cabron',
  'cachufla',
  'cachufleta',
  'cagada',
  'cagar',
  'cagón',
  'cajeta',
  'cajetudo',
  'cara de pija mal chupada',
  'chavista',
  'chele',
  'chilampi',
  'chilote',
  'chinga',
  'choreo',
  'choriplanero',
  'choto',
  'chucha la wea',
  'chupa chota',
  'chupa pija',
  'chupa poronga',
  'chupa verga',
  'chupachota',
  'chupala',
  'chupame la pija',
  'chupapija',
  'chupaporonga',
  'chupaverga',
  'ciruja',
  'cobani',
  'concha de tu vieja',
  'conchetumadre',
  'conchudo',
  'coñazo',
  'coño',
  'corneta',
  'cornudo',
  'cuca',
  'culea',
  'culeao',
  'culia',
  'culiado',
  'culo roto',
  'down',
  'estólido',
  'falopa',
  'faso',
  'fasuli',
  'feminazi',
  'fome',
  'forro',
  'forros',
  'fraca',
  'fracasado',
  'gaznápiro',
  'gil',
  'gilastro',
  'globoludo',
  'gorilón',
  'gorreada',
  'gorreado',
  'govir',
  'guevo',
  'guevon',
  'hdp',
  'hijo de puta',
  'hippie con osde',
  'idiota',
  'imbécil',
  'infradotada',
  'infradotado',
  'inútil',
  'ladilla',
  'Ladron',
  'lechazo',
  'machirulo',
  'machista',
  'mal cogido',
  'malparido',
  'mamaguevo',
  'manga de putos',
  'marico',
  'maricon',
  'mascachota',
  'menso',
  'merca',
  'mierda',
  'milipili',
  'mogolico',
  'mongo',
  'mongui',
  'mulo provinciano',
  'nashe',
  'nazi',
  'nepe',
  'ortiva',
  'orto',
  'pajereada',
  'pajero',
  'palurdo',
  'panocha',
  'papo',
  'papudito',
  'pario',
  'pedofilia',
  'pedófilo',
  'pelotuda',
  'pendejo',
  'perkin',
  'peroncho',
  'perquin',
  'Perra',
  'peruca',
  'petera',
  'pibis',
  'pico',
  'pija',
  'pijacorta',
  'pijaso',
  'pijazo',
  'pijón',
  'pinche',
  'pinchila',
  'pinga',
  'pingo',
  'pito',
  'planero',
  'poronga',
  'porongas',
  'chota',
  'chotas',
  'putas',
  'mierdas',
  'putos',
  'trolos',
  'trolas',
  'glande',
  'glandes',
  'put4',
  'puta',
  'puta bida',
  'puta que te pario',
  'putaso',
  'putazo',
  'puticlub',
  'putita',
  'putona',
  'putorda',
  'qlia',
  'retardado',
  'retrasado',
  'sapo culiao',
  'sapo ql',
  'sidoso',
  'simp',
  'siome',
  'sobaquena',
  'sobaquenas',
  'sopenco',
  'sorete',
  'tarada',
  'tarado',
  'tarupido',
  'terco',
  'terrible virgo',
  'terrorista',
  'tonto',
  'trabuco',
  'trava',
  'travesti',
  'trola',
  'trolaso',
  'trolazo',
  'trolo',
  'tuca',
  'tula',
  'tuna',
  'tunuda',
  'ura',
  'vagabundo',
  'vaginosis',
  'verga',
  'vergón',
  'vergotas',
  'versero',
  'vigilante',
  'villero',
  'violador',
  'wea',
  'webon',
  'weon',
  'yarco',
  'yuta',
  'zorra',
  'zorruda',
  'zorrudo',
  'puta',
  'merda',
  'caralho',
  'foda-se',
  'bosta',
  'cuzão',
  'vadia',
  'desgraçado',
  'arrombado',
  'cabrão',
  'safado',
  'vagabundo',
  'imbecil',
  'idiota',
  'otário',
  'babaca',
  'trouxa',
  'corno',
  'galinha',
  'burro',
  'lesado',
  'mula',
  'chifrudo',
  'palerma',
  'tapado',
  'paspalho',
  'jumento',
  'anta',
  'energúmeno',
  'punheta',
  'fudido',
  'broxa',
  'chupador',
  'maloqueiro',
  'safada',
  'buceta',
  'xota',
  'xana',
  'piroca',
  'rola',
  'gozar',
  'cu',
  'pinto',
  'racha',
  'siririca',
  'boquete',
  'punheteiro',
  'gozador',
  'tesão',
  'baitola',
  'viado',
  'bichona',
  'frango',
  'bicha',
  'porra',
  'caralhudo',
  'cagão',
  'merda',
  'traste',
  'pamonha',
  'lazarento',
  'pé rapado',
  'tarado',
  'sacana',
  'sem-vergonha',
  'maldito',
  'canalha',
  'bastardo',
  'rapariga',
  'piranha',
  'rameira',
  'lambisgóia',
  'bruaca',
  'quenga',
  'desavergonhada',
  'biscate',
  'cachorra',
  'porca',
  'pau no cu',
  'toma no cu',
  'vai se foder',
  'seu lixo',
  'cretino',
  'nojento',
  'verme',
  'escroto',
  'bostinha',
  'fedido',
  'fedorento',
  'rato',
  'vagabunda',
  'inútil',
  'viado',
]
