/* eslint-disable prettier/prettier */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { CustomerRoles } from '../constants/customerRoles'
import CallService from '../services/callService'

export const CALL_CARD_PRODUCTS = 'CALL_CARD_PRODUCTS'
export const CALL_CARD_CHECKOUT = 'CALL_CARD_CHECKOUT'
export const CALL_CARD_CART = 'CALL_CARD_CART'
export const CALL_CARD_PRODUCT_DETAIL = 'CALL_CARD_PRODUCT_DETAIL'
export const CALL_CARD_CHECKOUT_SUCCESS = 'CALL_CARD_CHECKOUT_SUCCESS'
export const CALL_CARD_CASH_CHECKOUT_SUCCESS = 'CALL_CARD_CASH_CHECKOUT_SUCCESS'
export const CALL_CARD_CASH_CHECKOUT_FAILURE = 'CALL_CARD_CASH_CHECKOUT_FAILURE'
export const CALL_CARD_CHECKOUT_FAILURE = 'CALL_CARD_CHECKOUT_FAILURE'
export const CALL_ACTION_CHAT = 'CALL_ACTION_CHAT'
export const CALL_ACTION_PRODUCTS = 'CALL_ACTION_PRODUCTS'
export const CALL_ACTION_CAMERAS = 'CALL_ACTION_CAMERAS'
export const SPAM_REENABLE_TIME = 15000
const SPAM_MAX_MESSAGES = 10
const SPAM_MAX_MESSAGES_TIME = 5000

export const notifyLikeMeesage = createAsyncThunk(
  'ui/notifyLikeMeesage',
  async (_, { dispatch }) => {
    const messageSent = await CallService.notifyChannelLikeMessage()

    if (messageSent) {
      dispatch(setRemoteLikeAnimation({ remoteLikeAnimation: true }))
    }
  },
)

export const setLikeMessageReceivedHandler = createAsyncThunk(
  'ui/likeMessageReceivedHandler',
  async (messageHandler) => {
    await CallService.setLikeMessageReceivedHandler(messageHandler)
  },
)

export const setExternalReactionReceivedHandler = createAsyncThunk(
  'ui/externalReactionReceivedHandler',
  async (messageHandler) => {
    await CallService.setExternalReactionReceived(messageHandler)
  },
)
export const setQuickAddReceivedHandler = createAsyncThunk(
  'ui/quickAddReceivedHandler',
  async (messageHandler) => {
    await CallService.setQuickAddReceived(messageHandler)
  },
)

export const setFinishEventCountdownMessageHandler = createAsyncThunk(
  'ui/finishEventcountdownMessageHandler',
  async (messageHandler) => {
    await CallService.setFinishEventCountdownMessageHandler(messageHandler)
  },
)

export const setStartEventCountdownMessageHandler = createAsyncThunk(
  'ui/startEventcountdownMessageHandler',
  async (messageHandler) => {
    await CallService.setStartEventCountdownMessageHandler(messageHandler)
  },
)

export const setDisplayPopUpMessageHandler = createAsyncThunk(
  'ui/setDisplayPopUpMessageHandler',
  async (messageHandler) => {
    await CallService.setDisplayPopUpMessageHandler(messageHandler)
  },
)

export const setVideoStartedHandler = createAsyncThunk(
  'ui/videoStartedHandler',
  async (videoStartedHandler) => {
    CallService.setVideoStartedHandler(videoStartedHandler)
  },
)

export const setChatMessageReceivedHandler = createAsyncThunk(
  'chat/messageReceivedHandler',
  async (messageHandler) => {
    await CallService.setChatMessageReceivedHandler(messageHandler)
  },
)

export const setMuteUserChatHandler = createAsyncThunk(
  'ui/setMuteUserChatHandler',
  async (messageHandler) => {
    await CallService.setMuteUserChatHandler(messageHandler)
  },
)

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    messages: [],
    spamControl: {
      dates: [],
      disabled: false,
    },
    lang: 'es',
    isOneToMany: false,
    notificationMessage: [],
    isNotificationVisible: false,
    goToCheckoutDialog: {
      orderData: null,
      showDialog: false,
      paymentGateway: null,
      externalUrl: null,
    },
    chat: {
      quotedMessage: null,
      pinnedMessage: null,
      moderatorMenuMessage: {
        message: null,
        mousePosition: null,
      },
      inputDisabled: false,
      cooldown: false,
      autoJoin: true,
    },
    showAskNameDialog: {
      open: false,
      allowCancel: true,
    },
    externalReaction: null,
    quickAdd: false,
    remoteLikeAnimation: false,
    localLikeAnimation: false,
    isLikeButtonVisible: false,
    activeCallCard: '',
    activeCallAction: CALL_ACTION_CHAT,
    videoDimensions: {
      width: null,
      height: null,
      aspectRatio: null,
    },
    carousel: {
      currentRow: 0,
    },
    cart: {
      showCheckoutIframe: false,
    },
    call: {
      onCloseMessage: false,
    },
    countdown: {
      disableStartEvent: false,
      eventStartServerDateTime: null,
      finisEventServerDateTime: null,
    },
    chatError: { showDialog: false, errorCode: null, errorMessage: null, errorTitle: null },
    banUserDialogVisible: false,
    muteUserDialogVisible: false,
    payPalDialogVisible: false,
    payPalButtonMounted: false,
    cannotDeleteMessage: {
      allowDelete: true,
    },
    showStoreInfo: false,
    changeMainVideo: true,
    scrollPosition: 0,
    stockError: false,
    postalCodeErrorMessage: '',
    showDraggable: false,
    showCloseCallDesktop: false,
  },
  reducers: {
    checkForSpam: (state) => {
      // If the user sends more than a number of messages
      // in less than some time, disable the user input
      let lastDate = new Date().getTime()

      while (
        state.spamControl.dates.length > 0 &&
        lastDate - state.spamControl.dates[0] > SPAM_MAX_MESSAGES_TIME
      ) {
        state.spamControl.dates.shift()
      }

      state.spamControl.dates.push(lastDate)
      if (state.spamControl.dates.length >= SPAM_MAX_MESSAGES) {
        state.spamControl.disabled = true
      }
    },
    setAutoJoinChat: (state, action) => {
      state.chat.autoJoin = action.payload
    },
    spamControlEnable: (state) => {
      state.spamControl.disabled = false
    },
    setIsNotificationVisible: (state, action) => {
      state.isNotificationVisible = action.payload.isNotificationVisible
    },
    setNotificationMessage: (state, action) => {
      state.notificationMessage = action.payload.notificationMessage
    },
    setQuotedMessage: (state, action) => {
      state.chat.quotedMessage = action.payload.quotedMessage
    },
    setChatCooldown: (state, action) => {
      state.chat.cooldown = action.payload
    },
    disableChatInput: (state, action) => {
      state.chat.inputDisabled = action.payload
    },
    clearQuotedMessage: (state) => {
      state.chat.quotedMessage = null
    },
    setPinnedMessage: (state, action) => {
      state.chat.pinnedMessage = action.payload
    },
    clearPinnedMessage: (state) => {
      state.chat.pinnedMessage = null
    },
    setModeratorMenuMessage: (state, action) => {
      state.chat.moderatorMenuMessage.message = action.payload
    },
    setMousePosition: (state, action) => {
      state.chat.moderatorMenuMessage.mousePosition = action.payload
    },
    setRemoteLikeAnimation: (state, action) => {
      state.remoteLikeAnimation = action.payload.remoteLikeAnimation
    },
    setLocalLikeAnimation: (state, action) => {
      state.localLikeAnimation = action.payload.localLikeAnimation
    },
    setIsOneToMany: (state, action) => {
      state.isOneToMany = action.payload.isOneToMany
      state.isLikeButtonVisible = action.payload.isOneToMany
      state.activeCallAction = action.payload.isMobile
        ? CALL_ACTION_PRODUCTS
        : action.payload.isOneToMany
        ? CALL_ACTION_CHAT
        : CALL_ACTION_CAMERAS
    },
    setActiveCallCard: (state, action) => {
      state.activeCallCard = action.payload
    },
    closeCallCard: (state) => {
      state.activeCallCard = ''
    },
    goToProducts: (state) => {
      state.activeCallCard = CALL_CARD_PRODUCTS
    },
    setActiveCallAction: (state, action) => {
      state.activeCallAction = action.payload
    },
    setVideoDimensions: (state, action) => {
      state.videoDimensions.width = action.payload.width
      state.videoDimensions.height = action.payload.height
      state.videoDimensions.aspectRatio = action.payload.aspectRatio
    },
    setCurrenRowOnCarousel: (state, action) => {
      state.carousel.currentRow = action.payload.currentRow
    },
    showCheckoutIframe: (state) => {
      state.cart.showCheckoutIframe = true
    },
    hideCheckoutIframe: (state) => {
      state.cart.showCheckoutIframe = false
    },
    showOnCloseMessageCall: (state) => {
      state.call.onCloseMessage = true
    },
    hideOnCloseMessageCall: (state) => {
      state.call.onCloseMessage = false
    },
    setShowStoreInfo: (state, action) => {
      state.showStoreInfo = action.payload.showStoreInfo
    },
    setPayPalButtonMounted: (state, action) => {
      state.payPalButtonMounted = action.payload
    },
    setShowAskNameDialog: (state, action) => {
      const { open, allowCancel = null } = action.payload
      state.showAskNameDialog.open = open

      if (allowCancel !== null) {
        state.showAskNameDialog.allowCancel = allowCancel
      }
    },
    setLanguage: (state, action) => {
      state.lang = action.payload
    },
    setExternalReaction: (state, action) => {
      state.externalReaction = action.payload.externalReaction
    },
    setQuickAdd(state, action) {
      state.quickAdd = action.payload.quickAdd
    },
    setBanUserDialogVisible: (state, action) => {
      state.banUserDialogVisible = action.payload
    },
    setGoToCheckoutDialog: (state, action) => {
      const { orderData = null, showDialog, paymentGateway, externalUrl = null } = action.payload
      state.goToCheckoutDialog.paymentGateway = paymentGateway
      state.goToCheckoutDialog.orderData = orderData
      state.goToCheckoutDialog.showDialog = showDialog
      if (externalUrl !== null) {
        state.goToCheckoutDialog.externalUrl = externalUrl
      }
    },
    setMuteUserDialogVisible: (state, action) => {
      state.muteUserDialogVisible = action.payload
    },
    setPayPalDialogVisible: (state, action) => {
      state.payPalDialogVisible = action.payload
    },
    setEventStartServerDateTime: (state, action) => {
      state.countdown.eventStartServerDateTime = action.payload
    },
    setFinishEventServerDateTime: (state, action) => {
      state.countdown.finisEventServerDateTime = action.payload
    },
    setCannotDeleteMessage: (state, action) => {
      state.cannotDeleteMessage.allowDelete = action.payload.allowDelete
    },
    setChatError: (state, action) => {
      const { showDialog, errorCode, errorMessage, errorTitle } = action.payload
      state.chatError.showDialog = showDialog
      state.chatError.errorCode = errorCode
      state.chatError.errorMessage = errorMessage
      state.chatError.errorTitle = errorTitle
    },
    setDisableStartEvent: (state, action) => {
      state.countdown.disableStartEvent = action.payload
    },
    setChangeMainVideo: (state, action) => {
      state.changeMainVideo = action.payload.changeMainVideo
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload
    },
    setMessageCount: (state, action) => {
      state.messageCount = action.payload
    },
    setStockError: (state, action) => {
      state.stockError = action.payload
    },
    setPostalCodeErrorMessage: (state, action) => {
      state.postalCodeErrorMessage = action.payload
    },
    setShowDraggable: (state, action) => {
      state.showDraggable = action.payload
    },
    setCloseCallDesktop: (state, action) => {
      state.showCloseCallDesktop = action.payload
    },
  },
})

//* ACTIONS
export const {
  setIsNotificationVisible,
  setNotificationMessage,
  setChatListIsShown,
  setRemoteLikeAnimation,
  setLocalLikeAnimation,
  setIsOneToMany,
  setLanguage,
  setAutoJoinChat,
  setVideoDimensions,
  setCurrenRowOnCarousel,
  showCheckoutIframe,
  hideCheckoutIframe,
  setActiveCallCard,
  closeCallCard,
  setActiveCallAction,
  showOnCloseMessageCall,
  hideOnCloseMessageCall,
  setShowStoreInfo,
  setShowAskNameDialog,
  setBanUserDialogVisible,
  setMuteUserDialogVisible,
  setPayPalDialogVisible,
  setGoToCheckoutDialog,
  setCannotDeleteMessage,
  setChangeMainVideo,
  setScrollPosition,
  setMessageCount,

  checkForSpam,
  spamControlEnable,
  disableChatInput,
  setStockError,
  setPostalCodeErrorMessage,
  setShowDraggable,
  setCloseCallDesktop,
  setQuotedMessage,
  setChatCooldown,
  clearQuotedMessage,
  setPinnedMessage,
  clearPinnedMessage,
  setFinishEventServerDateTime,
  setEventStartServerDateTime,
  setPayPalButtonMounted,
  setMousePosition,
  setModeratorMenuMessage,
  setChatError,
  goToProducts,
  setDisableStartEvent,
  setExternalReaction,
  setQuickAdd,
} = uiSlice.actions

//* SELECTORS
export const selectNotificationMessage = (state) => state.ui.notificationMessage
export const selectIsNotificationVisible = (state) => state.ui.isNotificationVisible
export const selectQuotedMessage = (state) => state.ui.chat.quotedMessage
export const selectPinnedMessage = (state) => state.ui.chat.pinnedMessage
export const selectModeratorMenuMessage = (state) => state.ui.chat.moderatorMenuMessage
export const selectExternalReaction = (state) => state.ui.externalReaction
export const selectRemoteLikeVisible = (state) => state.ui.remoteLikeAnimation
export const selectLocalLikeVisible = (state) => state.ui.localLikeAnimation
export const selectIsLikeButtonVisible = (state) => state.ui.isLikeButtonVisible
export const selectActiveCallCard = (state) => state.ui.activeCallCard
export const selectActiveCallAction = (state) => state.ui.activeCallAction
export const selectVideoDimensions = (state) => state.ui.videoDimensions
export const selectCurrentRowOnCarousel = (state) => state.ui.carousel.currentRow
export const selectIsCheckoutIframeVisible = (state) => state.ui.cart.showCheckoutIframe
export const selectOnCloseMessageCall = (state) => state.ui.call.onCloseMessage
export const selectCartEnabled = (state) =>
  state.store.current.hasCheckout &&
  (state.store.current.isOneToManySale || state.call.role === CustomerRoles.BUYER)
export const selectShowStoreInfo = (state) => state.ui.showStoreInfo
export const selectPayPalButtonMounted = (state) => state.ui.payPalButtonMounted
export const selectShowAskNameDialog = (state) => state.ui.showAskNameDialog.open
export const selectAllowCancelAskNameDialog = (state) => state.ui.showAskNameDialog.allowCancel
export const selectBanUserDialogVisible = (state) => state.ui.banUserDialogVisible
export const selectMuteUserDialogVisible = (state) => state.ui.muteUserDialogVisible
export const selectPayPalDialogVisible = (state) => state.ui.payPalDialogVisible
export const selectCannotDeleteMessage = (state) => state.ui.cannotDeleteMessage
export const selectGoToCheckout = (state) => state.ui.goToCheckoutDialog
export const selectMainVideo = (state) => state.ui.changeMainVideo
export const selectEventStartServerDateTime = (state) => state.ui.countdown.eventStartServerDateTime
export const selectFinishEventServerDateTime = (state) =>
  state.ui.countdown.finisEventServerDateTime
export const selectScrollPosition = (state) => state.ui.scrollPosition
export const selectMessages = (state) => state.ui.messages
export const selectAutoJoinChat = (state) => state.ui.chat.autoJoin
export const selectChatDisabled = (state) => state.ui.spamControl.disabled
export const selectChatMuted = (state) => state.ui.chat.inputDisabled
export const selectChatCooldown = (state) => state.ui.chat.cooldown
export const selectIsStockError = (state) => state.ui.stockError
export const selectPostalCodeErrorMessage = (state) => state.ui.postalCodeErrorMessage
export const selectShowDraggable = (state) => state.ui.showDraggable
export const selectCloseCallDesktop = (state) => state.ui.showCloseCallDesktop
export const selectChatError = (state) => state.ui.chatError
export const selectDisableStartEvent = (state) => state.ui.countdown.disableStartEvent
export const selectStoreLang = (state) => state.ui.lang
export const selectQuickAdd = (state) => state.ui.quickAdd
//* REDUCER
export default uiSlice.reducer
