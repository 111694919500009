import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CloseCall } from './CloseCall'
import { PIPButton } from '../GJKit/PIPButton'
import { ConfigButton } from './DesktopCards/ConfigButton'
import { TopNavBarContainer } from './Layout.styles'
import { FullScreenToggleButton } from './FullScreenButton'
import qs from 'qs'
import UtmUtils from '../../utils/utmUtils'
import { useUtm } from '@gojiraf/useutm'

export const TopNavBar = ({
  isDesktop = false,
  isModerator,
  dispatchFinishCountdown,
  dispatchStartCountdown,
  onCallFinished,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { allowFullScreen } = useUtm(utm_medium)

  return (
    <TopNavBarContainer isDesktop={isDesktop}>
      {isModerator && (
        <ConfigButton
          dispatchFinishCountdown={dispatchFinishCountdown}
          dispatchStartCountdown={dispatchStartCountdown}
        />
      )}
      {!isDesktop && <PIPButton isMobile={!isDesktop} />}
      {allowFullScreen && (
        <FullScreenToggleButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      )}
      {!isFullscreen && <CloseCall onCallFinished={onCallFinished} />}
    </TopNavBarContainer>
  )
}

TopNavBar.propTypes = {
  isModerator: PropTypes.bool.isRequired,
  onCallFinished: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool,
  dispatchFinishCountdown: PropTypes.func,
  dispatchStartCountdown: PropTypes.func,
}
