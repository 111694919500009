import React from 'react'
import PropTypes from 'prop-types'
import { MainHeading } from './style'

export const ChatHeader = ({ usersCount }) => {
  return (
    <MainHeading data-test="main-heading-text">
      Chat{usersCount !== undefined ? ` (${usersCount > 0 ? usersCount - 1 : 0})` : ''}
    </MainHeading>
  )
}

ChatHeader.propTypes = {
  usersCount: PropTypes.any,
}
