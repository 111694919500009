import React from 'react'
import Linkify from 'react-linkify/dist/components/Linkify'
import { AnchorLink } from '../style'
import PropTypes from 'prop-types'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'

const MessageWithLink = ({ children, isDesktop, logType, view }) => {
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()

  const handleClickUrlInChat = () => {
    gaEventTracker(`InCall > ${view}`, logType)
    matomoTrackEvent(`InCall > ${view}`, logType)
  }

  const getTruncatedUrl = (url) => {
    if (url.length > 85) {
      const start = url.substring(0, 40)
      const end = url.substring(url.length - 40)
      return `${start}[...]${end}`
    }

    return url
  }
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => {
        return (
          <AnchorLink
            target="_blank"
            href={decoratedHref}
            key={key}
            isDesktop={isDesktop}
            rel="noreferrer"
            onClick={() => handleClickUrlInChat()}
          >
            {getTruncatedUrl(decoratedText)}
          </AnchorLink>
        )
      }}
    >
      {children}
    </Linkify>
  )
}
MessageWithLink.propTypes = {
  children: PropTypes.element,
  isDesktop: PropTypes.bool,
  logType: PropTypes.string,
  view: PropTypes.string,
}
export default MessageWithLink
