import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import outputs from './amplify_outputs.json'

Amplify.configure(outputs)

const client = generateClient()

class NotificationsService {
  constructor(channel) {
    if (NotificationsService.instance) {
      return NotificationsService.instance
    }
    this.channel = channel
    this.subscription = null
    NotificationsService.instance = this
  }

  static getInstance(channel) {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService(channel)
    }
    return NotificationsService.instance
  }

  async sendMessage(message) {
    if (!this.channel) {
      console.error('Event ID not set')
      throw new Error('Event ID not set')
    }
    await client.models.Notification.create({ ...message, room: this.channel })
  }

  subscribe(onNotificationReceived) {
    this.subscription = client.models.Notification.onCreate({
      filter: {
        room: {
          eq: this.channel,
        },
      },
    }).subscribe({
      next: (value) => {
        onNotificationReceived(value)
      },
      error: (error) => console.error(error),
    })
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

export default NotificationsService
