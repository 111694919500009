import React from 'react'
import PropTypes from 'prop-types'
import {
  Message,
  MessageBox,
  MessageContainer,
  UserIcon,
  ModeratorIcon,
  UserIconContainer,
  Username,
} from '../style'
import { useTranslation } from 'react-i18next'
import MessageWithLink from './MessageWithLink'
import { useSelector } from 'react-redux'
import { selectCurrentStore } from '../../../reducers/storeSlice'

export const CommonMessage = ({ message, isDesktop, isModerator }) => {
  const { t } = useTranslation()
  const store = useSelector(selectCurrentStore)
  const clickeableLinks = store.storeConfigurations.features.clickeableLinks
  const textMessage = (message) => {
    if (message.type === 'deleted') {
      return t('chat.deletedMessage')
    }
    return message.text
  }

  return (
    <MessageContainer isModerator={isModerator} isDesktop={isDesktop}>
      <MessageBox>
        <UserIconContainer>
          {isModerator ? (
            <ModeratorIcon isDesktop={isDesktop} />
          ) : (
            <UserIcon isDesktop={isDesktop} />
          )}
        </UserIconContainer>
        <Username isDesktop={isDesktop}>{`${message.username}: `}</Username>
        {clickeableLinks === true || isModerator ? (
          <MessageWithLink view="Chat" logType="click-url-in-chat" isDesktop={isDesktop}>
            <Message isDesktop={isDesktop} data-test="user-message">
              {textMessage(message)}
            </Message>
          </MessageWithLink>
        ) : (
          <Message isDesktop={isDesktop} data-test="user-message">
            {textMessage(message)}
          </Message>
        )}
      </MessageBox>
    </MessageContainer>
  )
}

CommonMessage.propTypes = {
  isDesktop: PropTypes.bool,
  message: PropTypes.object.isRequired,
  isModerator: PropTypes.bool.isRequired,
}
