import React from 'react'
import PropTypes from 'prop-types'
import { MessagesListItem } from './style'
import { CommonMessage } from './messages/CommonMessage'
import { RepliedMessage } from './messages/RepliedMessage'
import { CustomerRoles } from '../../constants/customerRoles'

export const ChatMessage = ({ isDesktop, user, message }) => {
  const showMessage = message.type !== 'deleted' || message.user.id === user.id

  const renderMessage = (message) => {
    const isModerator = message.role === CustomerRoles.MODERATOR

    if (message.type === 'reply') return <RepliedMessage message={message} isDesktop={isDesktop} />

    return <CommonMessage message={message} isDesktop={isDesktop} isModerator={isModerator} />
  }

  return <>{showMessage ? <MessagesListItem>{renderMessage(message)}</MessagesListItem> : null}</>
}

ChatMessage.propTypes = {
  isDesktop: PropTypes.bool,
  user: PropTypes.object.isRequired,
  message: PropTypes.object,
}
