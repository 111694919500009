"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to2, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to2, key) && key !== except)
        __defProp(to2, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to2;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  DefaultBuyerName: () => DefaultBuyerName,
  login: () => login,
  refresh: () => refresh,
  useAuth: () => useAuth,
  useAuthStore: () => useAuthStore
});
module.exports = __toCommonJS(src_exports);

// src/useAuthStore.ts
var import_dayjs = __toESM(require("dayjs"));
var import_zustand = require("zustand");
var import_middleware = require("zustand/middleware");

// src/buyerNames.ts
var DefaultBuyerName = null;

// src/config.ts
var {
  REACT_APP_BAN_TIME_NUMBER = 1,
  REACT_APP_BAN_TIME_UNIT = "day"
} = process.env;
var BAN_TIME_UNIT = REACT_APP_BAN_TIME_UNIT;
var BAN_TIME_NUMBER = Number(REACT_APP_BAN_TIME_NUMBER);
var config = {
  BAN_TIME_UNIT,
  BAN_TIME_NUMBER
};

// src/useAuthStore.ts
var {
  BAN_TIME_NUMBER: BAN_TIME_NUMBER2,
  BAN_TIME_UNIT: BAN_TIME_UNIT2
} = config;
var useAuthStore = (0, import_zustand.create)()(
  (0, import_middleware.persist)((set) => ({
    user: {
      id: "",
      name: DefaultBuyerName,
      role: "BUYER",
      tokens: null,
      bans: {},
      mutes: {}
    },
    userForBan: {
      id: null,
      name: ""
    },
    userForMute: {
      id: null,
      name: "",
      channelId: null
    },
    addBan: (storeId) => set((state) => {
      const banTime = (0, import_dayjs.default)().add(Number(BAN_TIME_NUMBER2), BAN_TIME_UNIT2).valueOf();
      const { bans } = state.user;
      bans[storeId] = banTime;
      return {
        user: __spreadProps(__spreadValues({}, state.user), {
          bans
        })
      };
    }),
    deleteBan: (storeId) => set((state) => {
      const { bans } = state.user;
      delete bans[storeId];
      return {
        user: __spreadProps(__spreadValues({}, state.user), {
          bans
        })
      };
    }),
    setBuyerCreds: (newUser) => set((state) => ({
      user: __spreadValues(__spreadValues({}, state.user), newUser)
    })),
    setModCreds: (newUser) => set((state) => ({
      user: __spreadValues(__spreadValues({}, state.user), newUser)
    })),
    setId: (id) => set((state) => ({
      user: __spreadProps(__spreadValues({}, state.user), {
        id
      })
    })),
    setUsername: (username) => set((state) => ({
      user: __spreadProps(__spreadValues({}, state.user), {
        name: username
      })
    })),
    setRole: (role) => set((state) => ({
      user: __spreadProps(__spreadValues({}, state.user), {
        role
      })
    })),
    setUserForBan: (data) => set(() => ({
      userForBan: data
    })),
    setUserForMute: (data) => set(() => ({
      userForMute: data
    }))
  }), {
    name: "auth",
    partialize: (state) => ({
      user: {
        bans: state.user.bans,
        mutes: state.user.mutes,
        name: DefaultBuyerName,
        role: "BUYER"
      }
    })
  })
);

// src/useAuth.ts
var useAuth = () => {
  const state = useAuthStore((currentState) => currentState);
  return __spreadValues({}, state);
};

// src/authService.ts
var import_await_to_js = __toESM(require("await-to-js"));
var import_uuid = require("uuid");

// src/authClient.ts
var import_axios = __toESM(require("axios"));
var {
  REACT_APP_AUTH_URL
} = process.env;
var authClient = import_axios.default.create({
  baseURL: REACT_APP_AUTH_URL
});

// src/authService.ts
var login = (_0) => __async(void 0, [_0], function* ({
  storeId,
  userName,
  role,
  pin
}) {
  let response = null;
  if (role === "MODERATOR" || role === "COHOST") {
    response = yield authClient.post("/login", {
      storeId,
      role,
      password: pin
    });
    useAuthStore.getState().setModCreds({
      id: (0, import_uuid.v4)(),
      role,
      name: userName,
      tokens: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
      }
    });
  } else {
    response = yield authClient.post("/login", {
      storeId
    });
    useAuthStore.getState().setBuyerCreds({
      id: response.data.payload.user.uuid,
      tokens: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
      }
    });
  }
  return response.data;
});
var refresh = (_0) => __async(void 0, [_0], function* ({ storeId }) {
  const { tokens } = useAuthStore.getState().user;
  if (tokens === null) {
    throw Error("Cannot refresh without a refresh token.");
  }
  const { refreshToken } = tokens;
  const [error, refreshedTokens] = yield (0, import_await_to_js.default)(authClient.post("/refresh", {
    refreshToken
  }));
  if (error) {
    const response = yield authClient.post("/login", {
      storeId
    });
    const loginTokens = response.data;
    useAuthStore.getState().setBuyerCreds({
      id: loginTokens.data.payload.user.uuid,
      tokens: {
        accessToken: loginTokens.data.accessToken,
        refreshToken: loginTokens.data.refreshToken
      }
    });
    return {
      accessToken: loginTokens.data.accessToken,
      refreshToken: loginTokens.data.refreshToken
    };
  }
  const {
    payload: {
      user: {
        uuid
      }
    },
    accessToken
  } = refreshedTokens.data;
  useAuthStore.getState().setBuyerCreds({
    id: uuid,
    tokens: {
      accessToken,
      refreshToken: refreshedTokens.data.refreshToken
    }
  });
  return {
    accessToken,
    refreshToken: refreshedTokens.data.refreshToken
  };
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DefaultBuyerName,
  login,
  refresh,
  useAuth,
  useAuthStore
});
