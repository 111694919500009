import React, { useEffect } from 'react'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { TopNavBarButton } from '../GJKit/Buttons/TopNavBarButton'
import PropTypes from 'prop-types'

const FullScreenToggleButton = ({ isFullscreen, setIsFullscreen }) => {
  // Función para alternar entre pantalla completa y salir de pantalla completa
  const toggleFullscreen = async () => {
    if (!isFullscreen) {
      const documentElement = document.documentElement // Todo el documento
      if (documentElement.requestFullscreen) {
        await documentElement.requestFullscreen()
      } else if (documentElement.webkitRequestFullscreen) {
        // Safari
        await documentElement.webkitRequestFullscreen()
      } else if (documentElement.mozRequestFullScreen) {
        // Firefox
        await documentElement.mozRequestFullScreen()
      } else if (documentElement.msRequestFullscreen) {
        // IE/Edge
        await documentElement.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        // Safari
        await document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        // Firefox
        await document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        // IE/Edge
        await document.msExitFullscreen()
      }
    }
  }

  // Monitorear el estado de pantalla completa
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    //Si se presiona F11
    //Cuando entras en modo de pantalla completa con F11, el navegador no utiliza la API de Fullscreen
    const handleResize = () => {
      const isFullscreen =
        window.innerHeight === screen.height && window.innerWidth === screen.width
      setIsFullscreen(isFullscreen)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('msfullscreenchange', handleFullscreenChange)
    window.addEventListener('resize', handleResize)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange)
      document.removeEventListener('msfullscreenchange', handleFullscreenChange)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <TopNavBarButton
        id="full-screen-call-button"
        data-test="full-screen-call"
        onClick={toggleFullscreen}
      >
        {!isFullscreen ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
      </TopNavBarButton>
    </div>
  )
}

FullScreenToggleButton.propTypes = {
  isFullscreen: PropTypes.func.isRequired,
  setIsFullscreen: PropTypes.func.isRequired,
}

export { FullScreenToggleButton }
