import React, { useState } from 'react'
import {
  MessagesContainer,
  MessagesFlexContainer,
  MessagesWrapper,
  MobileChatOverlay,
} from './style'
import PropTypes from 'prop-types'
import { PinnedMessage } from './messages/PinnedMessage'
import { VirtualizedMessageList } from './VirtualizedMessageList'

const Messages = ({
  isDesktop = false,
  chatOpen,
  user,
  children,
  messages,
  hasMoreMessages,
  fetchMoreMessages,
  toggleChatMobile,
  isFetchingMoreMessages,
  pinnedMessage,
}) => {
  const [isGestureLocked, setIsGestureLocked] = useState(false)
  const [startY, setStartY] = useState(0)
  const [endY, setEndY] = useState(0)

  const unlockGesture = () => {
    setTimeout(() => {
      setIsGestureLocked(false)
    }, 300)
  }

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY)
  }

  const handleTouchMove = (e) => {
    if (isGestureLocked) return
    setEndY(e.touches[0].clientY)
    const deltaY = endY - startY

    if (deltaY > 50 && chatOpen) {
      toggleChatMobile(false)
      setIsGestureLocked(true)
      unlockGesture()
    } else if (deltaY < -50 && !chatOpen) {
      toggleChatMobile(true)
      setIsGestureLocked(true)
      unlockGesture()
    }
  }

  return (
    <>
      <MessagesWrapper>
        {!isDesktop && (
          <MobileChatOverlay
            onClick={() => toggleChatMobile(!chatOpen)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {children}
          </MobileChatOverlay>
        )}
        <MessagesFlexContainer isDesktop={isDesktop}>
          <MessagesContainer isDesktop={isDesktop} data-test="messages-container">
            {(messages.length === 0 && !isDesktop) || !chatOpen ? null : (
              <>
                <VirtualizedMessageList
                  messages={messages}
                  hasMoreMessages={hasMoreMessages}
                  fetchMoreMessages={fetchMoreMessages}
                  isFetchingMoreMessages={isFetchingMoreMessages}
                  user={user}
                  isDesktop={isDesktop}
                />
                {pinnedMessage && <PinnedMessage message={pinnedMessage} isDesktop={isDesktop} />}
              </>
            )}
          </MessagesContainer>
        </MessagesFlexContainer>
      </MessagesWrapper>
    </>
  )
}

Messages.propTypes = {
  isDesktop: PropTypes.bool,
  chatOpen: PropTypes.bool,
  isFetchingMoreMessages: PropTypes.bool,
  user: PropTypes.object.isRequired,
  children: PropTypes.element,
  hasMoreMessages: PropTypes.bool,
  fetchMoreMessages: PropTypes.func,
  toggleChatMobile: PropTypes.func,
  messages: PropTypes.array,
  pinnedMessage: PropTypes.object,
}

export { Messages }
