import React, { useMemo, useState } from 'react'
import StoreUtils from '../../utils/storeUtils'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectActiveCallCard } from '../../reducers/uiSlice'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useViews } from 'state'
import { PDPContainer, Modal, useProducts, useCart, usePDP, Products as ProductsList } from 'ui'
import { AnimatePresence } from 'framer-motion'
import { Checkout } from '../GJKit/Checkout'
import { OrderStatus } from '../Checkout/OrderStatus'
import { selectSeller } from '../../reducers/callSlice'
import { useAuth } from '@gojiraf/auth'
import { JoinChatForm } from '../Chat/JoinChatForm'
import { Products } from '../Products/Products'
import { useCheckout } from '../../hooks/useCheckout'
import { ChatModule } from '../Chat/ChatModule'
import { useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { useAmplifyChat } from '@gojiraf/chat'

const CallAction = ({ onCallFinished }) => {
  const [chatOpen, setChatOpen] = useState(true)
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { sendEventPostToElastic } = useElasticEventTracker()
  const { user } = useAuth()
  const { id: sellerId } = useSelector(selectSeller)
  const store = useSelector(selectCurrentStore)
  const { state: chatState } = useAmplifyChat()
  const activeCallCard = useSelector(selectActiveCallCard)
  const { type: paymentType } = store.paymentGateways.find(
    (paymentGatewayItem) => paymentGatewayItem.isDefault === true,
  )
  const isLightIntegration = StoreUtils.getIsLightIntegration(store.paymentGateways)
  const {
    handlePIPLightIntegration,
    getItemsInCart,
    hasOwnCheckout,
    handleAddProductToCart,
    handleOnBuyClicked,
  } = useCheckout(store)
  const { send: sendPDP } = usePDP()
  const { state, send: sendViews } = useViews()
  const { state: cartState } = useCart()
  const canBuy = useMemo(
    () => !!cartState.context.variants?.size,
    [cartState.context.variants?.size],
  )
  const { products, getProductsBySearchTerm } = useProducts({
    callId: sellerId,
  })

  const showProducts = () => {
    sendViews({
      type: 'SHOW_PRODUCTS',
    })
  }

  const onProductsListClose = () => {
    sendViews({
      type: 'SHOW_CHAT',
    })
  }

  const handleItemClicked = (product) => {
    matomoTrackEvent('InCall > Products', `click-product-detail-button [${product.name}]`)
    sendEventPostToElastic({
      event: 'products',
      eventType: 'click-product-detail-button',
      productName: product.name,
    })
    sendPDP({
      type: 'SET_PRODUCT',
      productId: product.id,
    })
    sendViews({
      type: 'SHOW_ITEM',
    })
  }

  const goBack = () => {
    sendViews({
      type: 'GO_BACK',
    })
  }

  return (
    <>
      <ChatModule
        chatCooldownDefault={store.storeConfigurations.features.chat.cooldown}
        chatOpen={chatOpen}
        setChatOpen={setChatOpen}
        user={user}
      />
      {state.matches('secondary.showingJoinChat') === true && (
        <Modal onClose={goBack}>
          <JoinChatForm eventId={chatState.eventId} />
        </Modal>
      )}
      <AnimatePresence>
        {state.matches('secondary.showingProducts') === true && (
          <Modal maxHeight="h-[80%]" size="LARGE" onClose={onProductsListClose}>
            <ProductsList
              getProductsBySearchTerm={getProductsBySearchTerm}
              paymentType={paymentType}
              isLightIntegration={isLightIntegration}
              canBuy={canBuy}
              products={products}
              onItemClicked={handleItemClicked}
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingCart') === true && (
          <Modal size="LARGE" onClose={goBack}>
            <Products products={products} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingPDP') === true && (
          <Modal size="LARGE" onClose={goBack}>
            <PDPContainer
              getItemsInCart={getItemsInCart}
              handlePIPLightIntegration={handlePIPLightIntegration}
              isLightIntegration={isLightIntegration}
              hasOwnCheckoutIntegration={hasOwnCheckout}
              handleAddProductToCart={handleAddProductToCart}
              handleBuyClicked={handleOnBuyClicked}
              goBack={showProducts}
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingOrder') === true && (
          <Modal onClose={showProducts}>
            <Checkout onCallFinished={onCallFinished} onGoBack={goBack} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state.matches('main.showingOrderStatus') === true && (
          <Modal onClose={goBack}>
            <OrderStatus activeCallCard={activeCallCard} />
          </Modal>
        )}
      </AnimatePresence>
      {state.matches('secondary.showingNone') === true && null}
    </>
  )
}

CallAction.propTypes = {
  onCallFinished: PropTypes.func.isRequired,
  setInitChat: PropTypes.func.isRequired,
}

export { CallAction }
