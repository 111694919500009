"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to2, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to2, key) && key !== except)
        __defProp(to2, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to2;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ChatProvider: () => ChatProvider,
  deleteMessage: () => deleteMessage,
  getNextPage: () => getNextPage,
  messageSelectionSet: () => messageSelectionSet,
  messageWithReplySelectionSet: () => messageWithReplySelectionSet,
  pinMessage: () => pinMessage,
  sendMessage: () => sendMessage,
  unpinMessage: () => unpinMessage,
  useAmplifyChat: () => useAmplifyChat
});
module.exports = __toCommonJS(src_exports);

// src/use-amplify-chat.tsx
var import_await_to_js = require("await-to-js");
var import_aws_amplify = require("aws-amplify");
var import_data = require("aws-amplify/data");
var import_react = require("react");
var import_uuid = require("uuid");

// src/amplify_outputs.json
var amplify_outputs_default = {
  auth: {
    user_pool_id: "us-east-1_l4KOBAy94",
    aws_region: "us-east-1",
    user_pool_client_id: "47g2idn6n9b0t10ej2cb6sdsi4",
    identity_pool_id: "us-east-1:a271c91b-610f-450f-8c01-f18bdb315fc8",
    mfa_methods: [],
    standard_required_attributes: [
      "email"
    ],
    username_attributes: [
      "email"
    ],
    user_verification_types: [
      "email"
    ],
    mfa_configuration: "NONE",
    password_policy: {
      min_length: 8,
      require_lowercase: true,
      require_numbers: true,
      require_symbols: true,
      require_uppercase: true
    },
    unauthenticated_identities_enabled: true
  },
  data: {
    url: "https://6hg35iz7e5fflmmv24bl3ly4py.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_region: "us-east-1",
    api_key: "da2-r57pcuj3wndn3f4xlzhq7d6vby",
    default_authorization_type: "API_KEY",
    authorization_types: [
      "AMAZON_COGNITO_USER_POOLS",
      "AWS_IAM"
    ],
    model_introspection: {
      version: 1,
      models: {
        Notification: {
          name: "Notification",
          fields: {
            id: {
              name: "id",
              isArray: false,
              type: "ID",
              isRequired: true,
              attributes: []
            },
            room: {
              name: "room",
              isArray: false,
              type: "String",
              isRequired: true,
              attributes: []
            },
            event: {
              name: "event",
              isArray: false,
              type: {
                enum: "NotificationEvent"
              },
              isRequired: false,
              attributes: []
            },
            payload: {
              name: "payload",
              isArray: false,
              type: "AWSJSON",
              isRequired: false,
              attributes: []
            },
            createdAt: {
              name: "createdAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: false,
              attributes: [],
              isReadOnly: true
            },
            updatedAt: {
              name: "updatedAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: false,
              attributes: [],
              isReadOnly: true
            }
          },
          syncable: true,
          pluralName: "Notifications",
          attributes: [
            {
              type: "model",
              properties: {}
            },
            {
              type: "auth",
              properties: {
                rules: [
                  {
                    allow: "public",
                    provider: "apiKey",
                    operations: [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          primaryKeyInfo: {
            isCustomPrimaryKey: false,
            primaryKeyFieldName: "id",
            sortKeyFieldNames: []
          }
        },
        Chat: {
          name: "Chat",
          fields: {
            id: {
              name: "id",
              isArray: false,
              type: "ID",
              isRequired: true,
              attributes: []
            },
            name: {
              name: "name",
              isArray: false,
              type: "String",
              isRequired: false,
              attributes: []
            },
            messages: {
              name: "messages",
              isArray: true,
              type: {
                model: "Message"
              },
              isRequired: false,
              attributes: [],
              isArrayNullable: true,
              association: {
                connectionType: "HAS_MANY",
                associatedWith: [
                  "chatId"
                ]
              }
            },
            pin: {
              name: "pin",
              isArray: false,
              type: {
                model: "Message"
              },
              isRequired: false,
              attributes: [],
              association: {
                connectionType: "HAS_ONE",
                associatedWith: [
                  "pinId"
                ],
                targetNames: []
              }
            },
            mutedUsers: {
              name: "mutedUsers",
              isArray: true,
              type: "String",
              isRequired: false,
              attributes: [],
              isArrayNullable: true
            },
            mutedIps: {
              name: "mutedIps",
              isArray: true,
              type: "String",
              isRequired: false,
              attributes: [],
              isArrayNullable: true
            },
            createdAt: {
              name: "createdAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: false,
              attributes: [],
              isReadOnly: true
            },
            updatedAt: {
              name: "updatedAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: false,
              attributes: [],
              isReadOnly: true
            }
          },
          syncable: true,
          pluralName: "Chats",
          attributes: [
            {
              type: "model",
              properties: {}
            },
            {
              type: "auth",
              properties: {
                rules: [
                  {
                    allow: "public",
                    provider: "apiKey",
                    operations: [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          primaryKeyInfo: {
            isCustomPrimaryKey: false,
            primaryKeyFieldName: "id",
            sortKeyFieldNames: []
          }
        },
        Message: {
          name: "Message",
          fields: {
            id: {
              name: "id",
              isArray: false,
              type: "ID",
              isRequired: true,
              attributes: []
            },
            text: {
              name: "text",
              isArray: false,
              type: "String",
              isRequired: true,
              attributes: []
            },
            userId: {
              name: "userId",
              isArray: false,
              type: "String",
              isRequired: true,
              attributes: []
            },
            username: {
              name: "username",
              isArray: false,
              type: "String",
              isRequired: true,
              attributes: []
            },
            type: {
              name: "type",
              isArray: false,
              type: {
                enum: "MessageType"
              },
              isRequired: false,
              attributes: []
            },
            role: {
              name: "role",
              isArray: false,
              type: {
                enum: "MessageRole"
              },
              isRequired: false,
              attributes: []
            },
            createdAt: {
              name: "createdAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: true,
              attributes: []
            },
            chatId: {
              name: "chatId",
              isArray: false,
              type: "ID",
              isRequired: true,
              attributes: []
            },
            chat: {
              name: "chat",
              isArray: false,
              type: {
                model: "Chat"
              },
              isRequired: false,
              attributes: [],
              association: {
                connectionType: "BELONGS_TO",
                targetNames: [
                  "chatId"
                ]
              }
            },
            pinId: {
              name: "pinId",
              isArray: false,
              type: "ID",
              isRequired: false,
              attributes: []
            },
            pin: {
              name: "pin",
              isArray: false,
              type: {
                model: "Chat"
              },
              isRequired: false,
              attributes: [],
              association: {
                connectionType: "BELONGS_TO",
                targetNames: [
                  "pinId"
                ]
              }
            },
            replyId: {
              name: "replyId",
              isArray: false,
              type: "ID",
              isRequired: false,
              attributes: []
            },
            reply: {
              name: "reply",
              isArray: false,
              type: {
                model: "Message"
              },
              isRequired: false,
              attributes: [],
              association: {
                connectionType: "BELONGS_TO",
                targetNames: [
                  "replyId"
                ]
              }
            },
            to: {
              name: "to",
              isArray: false,
              type: {
                model: "Message"
              },
              isRequired: false,
              attributes: [],
              association: {
                connectionType: "HAS_ONE",
                associatedWith: [
                  "replyId"
                ],
                targetNames: []
              }
            },
            updatedAt: {
              name: "updatedAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: false,
              attributes: [],
              isReadOnly: true
            }
          },
          syncable: true,
          pluralName: "Messages",
          attributes: [
            {
              type: "model",
              properties: {}
            },
            {
              type: "key",
              properties: {
                name: "messagesByChatIdAndCreatedAt",
                queryField: "listMessageByChatIdAndCreatedAt",
                fields: [
                  "chatId",
                  "createdAt"
                ]
              }
            },
            {
              type: "auth",
              properties: {
                rules: [
                  {
                    allow: "public",
                    provider: "apiKey",
                    operations: [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          primaryKeyInfo: {
            isCustomPrimaryKey: false,
            primaryKeyFieldName: "id",
            sortKeyFieldNames: []
          }
        }
      },
      enums: {
        NotificationEvent: {
          name: "NotificationEvent",
          values: [
            "LIKE",
            "SHOW_POPUP",
            "HIDE_POPUP",
            "START_EVENT_COUNTDOWN",
            "FINISH_EVENT_COUNTDOWN",
            "SHOW_QUICK_ADD",
            "HIDE_QUICK_ADD",
            "SHOW_REACTIONS",
            "EVENT_FINISHED",
            "MUTE_USER_CHAT",
            "SLOW_MODE_CHAT",
            "EVENT_PRODUCTS_CHANGED"
          ]
        },
        MessageType: {
          name: "MessageType",
          values: [
            "default",
            "reply"
          ]
        },
        MessageRole: {
          name: "MessageRole",
          values: [
            "BUYER",
            "MODERATOR"
          ]
        },
        BeforeCreateMessageType: {
          name: "BeforeCreateMessageType",
          values: [
            "default",
            "reply"
          ]
        },
        BeforeCreateMessageRole: {
          name: "BeforeCreateMessageRole",
          values: [
            "BUYER",
            "MODERATOR"
          ]
        }
      },
      nonModels: {},
      mutations: {
        beforeCreateMessage: {
          name: "beforeCreateMessage",
          isArray: false,
          type: {
            model: "Message"
          },
          isRequired: false,
          arguments: {
            chatId: {
              name: "chatId",
              isArray: false,
              type: "ID",
              isRequired: true
            },
            text: {
              name: "text",
              isArray: false,
              type: "String",
              isRequired: true
            },
            userId: {
              name: "userId",
              isArray: false,
              type: "String",
              isRequired: true
            },
            username: {
              name: "username",
              isArray: false,
              type: "String",
              isRequired: true
            },
            createdAt: {
              name: "createdAt",
              isArray: false,
              type: "AWSDateTime",
              isRequired: true
            },
            type: {
              name: "type",
              isArray: false,
              type: {
                enum: "BeforeCreateMessageType"
              },
              isRequired: false
            },
            role: {
              name: "role",
              isArray: false,
              type: {
                enum: "BeforeCreateMessageRole"
              },
              isRequired: false
            },
            pinId: {
              name: "pinId",
              isArray: false,
              type: "ID",
              isRequired: false
            },
            replyId: {
              name: "replyId",
              isArray: false,
              type: "ID",
              isRequired: false
            }
          }
        },
        muteUser: {
          name: "muteUser",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          arguments: {
            chatId: {
              name: "chatId",
              isArray: false,
              type: "ID",
              isRequired: true
            },
            userId: {
              name: "userId",
              isArray: false,
              type: "ID",
              isRequired: true
            }
          }
        }
      }
    }
  },
  version: "1.1"
};

// src/use-amplify-chat.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var messageSelectionSet = [
  "id",
  "text",
  "createdAt",
  "updatedAt",
  "chatId",
  "pinId",
  "userId",
  "username",
  "type",
  "role",
  "replyId"
];
var messageWithReplySelectionSet = [
  ...messageSelectionSet,
  "reply.*"
];
import_aws_amplify.Amplify.configure(amplify_outputs_default);
var client = (0, import_data.generateClient)();
var ChatStateContext = (0, import_react.createContext)(void 0);
var initialState = (eventId) => ({
  status: "disconnected",
  eventId,
  messages: [],
  nextToken: void 0,
  pin: void 0,
  replyTo: void 0,
  isUserMuted: false
});
function chatReducer(state, action) {
  switch (action.type) {
    case "addMessage": {
      const newMessages = [...state.messages];
      newMessages.push(action.payload.message);
      return __spreadProps(__spreadValues({}, state), {
        messages: newMessages
      });
    }
    case "addMessages": {
      const newMessages = [
        ...action.payload.messages,
        ...state.messages
      ];
      console.log("prueba", action.payload.messages);
      return __spreadProps(__spreadValues({}, state), {
        messages: newMessages,
        nextToken: action.payload.nextToken
      });
    }
    case "deleteMessage": {
      const newMessages = [...state.messages];
      const index = newMessages.findIndex(
        (message) => message.id === action.payload.message.id
      );
      if (index !== -1) {
        newMessages.splice(index, 1);
      }
      return __spreadProps(__spreadValues({}, state), {
        messages: newMessages
      });
    }
    case "setPin": {
      return __spreadProps(__spreadValues({}, state), {
        pin: action.payload.pin
      });
    }
    case "unpinMessage": {
      return __spreadProps(__spreadValues({}, state), {
        pin: void 0
      });
    }
    case "replyTo": {
      return __spreadProps(__spreadValues({}, state), {
        replyTo: action.payload.replyTo
      });
    }
    case "resetReply": {
      return __spreadProps(__spreadValues({}, state), {
        replyTo: void 0
      });
    }
    case "reset": {
      return initialState(state.eventId);
    }
    case "connect": {
      return __spreadProps(__spreadValues({}, state), {
        status: "connected"
      });
    }
    case "setEvent": {
      return __spreadProps(__spreadValues({}, state), {
        eventId: action.payload.eventId
      });
    }
    case "setUserMuted": {
      return __spreadProps(__spreadValues({}, state), {
        isUserMuted: action.payload.muted
      });
    }
  }
}
function ChatProvider({
  eventId = void 0,
  fetchOnMount = false,
  subscribeOnMount = false,
  children
}) {
  const [state, dispatch] = (0, import_react.useReducer)(chatReducer, initialState(eventId));
  const subscribeToCreate = (0, import_react.useCallback)(
    (onMessage) => {
      const subscription = client.models.Message.onCreate({
        filter: {
          chatId: {
            eq: state.eventId
          }
        },
        selectionSet: messageWithReplySelectionSet
      }).subscribe({
        next: (message) => onMessage(message)
      });
      return subscription;
    },
    [state.eventId]
  );
  const subscribeToDelete = (0, import_react.useCallback)(
    (onMessage) => {
      const subscription = client.models.Message.onDelete({
        filter: {
          chatId: {
            eq: state.eventId
          }
        },
        selectionSet: messageWithReplySelectionSet
      }).subscribe({
        next: (message) => onMessage(message)
      });
      return subscription;
    },
    [state.eventId]
  );
  const subscribeToUpdate = (0, import_react.useCallback)(
    (onMessage) => {
      const subscription = client.models.Message.onUpdate({
        filter: {
          chatId: {
            eq: state.eventId
          }
        },
        selectionSet: messageWithReplySelectionSet
      }).subscribe({
        next: (message) => onMessage(message)
      });
      return subscription;
    },
    [state.eventId]
  );
  const shouldUnpinPinned = (0, import_react.useCallback)(
    (message) => {
      if (state.pin === void 0)
        return;
      if (state.pin.id === message.id) {
        dispatch({
          type: "unpinMessage"
        });
      }
    },
    [state.pin, dispatch]
  );
  (0, import_react.useEffect)(() => {
    if (state.status === "disconnected")
      return;
    if (state.eventId === void 0)
      return;
    void getChat(dispatch, state.eventId);
    return () => {
      dispatch({ type: "reset" });
    };
  }, [state.status, state.eventId]);
  (0, import_react.useEffect)(() => {
    if (state.status === "disconnected" && subscribeOnMount === false || state.eventId === void 0)
      return;
    const onCreateSubscription = subscribeToCreate(
      (message) => {
        dispatch({ type: "addMessage", payload: { message } });
        if (message.pinId !== null) {
          dispatch({ type: "setPin", payload: { pin: message } });
        }
      }
    );
    const onDeleteSubscription = subscribeToDelete(
      (message) => {
        dispatch({ type: "deleteMessage", payload: { message } });
      }
    );
    const onUpdateSubscription = subscribeToUpdate(
      (message) => {
        if (message.pinId !== null) {
          dispatch({
            type: "setPin",
            payload: { pin: message }
          });
        } else {
          shouldUnpinPinned(message);
        }
      }
    );
    return () => {
      onCreateSubscription.unsubscribe();
      onDeleteSubscription.unsubscribe();
      onUpdateSubscription.unsubscribe();
    };
  }, [
    subscribeOnMount,
    state.status,
    state.eventId,
    subscribeToCreate,
    subscribeToDelete,
    subscribeToUpdate,
    shouldUnpinPinned
  ]);
  const value = { state, dispatch };
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ChatStateContext.Provider, { value, children });
}
function useAmplifyChat() {
  const context = (0, import_react.useContext)(ChatStateContext);
  if (context === void 0) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}
function sendMessage(dispatch, state, message) {
  return __async(this, null, function* () {
    if (message.chatId === null)
      return;
    const _a = message, { replyId } = _a, rest = __objRest(_a, ["replyId"]);
    const createdAt = (/* @__PURE__ */ new Date()).toISOString();
    if (state.isUserMuted === true) {
      const fakeId = (0, import_uuid.v4)();
      dispatch({
        type: "addMessage",
        payload: {
          message: __spreadProps(__spreadValues({}, message), {
            id: fakeId,
            type: "default",
            createdAt,
            updatedAt: createdAt,
            pinId: null,
            reply: null
          })
        }
      });
      return;
    }
    yield (0, import_await_to_js.to)(
      client.mutations.beforeCreateMessage(__spreadValues(__spreadValues({
        createdAt,
        type: replyId !== null ? "reply" : "default"
      }, replyId !== null && { replyId }), rest))
    );
    dispatch({
      type: "resetReply"
    });
  });
}
function getChat(dispatch, eventId) {
  return __async(this, null, function* () {
    const [error, result] = yield (0, import_await_to_js.to)(
      client.models.Chat.get(
        {
          id: eventId
        },
        {
          selectionSet: ["id", "pin.*"]
        }
      )
    );
    if (error !== null) {
      console.log(error);
      return;
    }
    const { data: chat, errors } = result;
    if (errors !== void 0 || chat === null) {
      console.log(errors);
      return;
    }
    console.debug({ chat });
    if (chat.pin !== null) {
      dispatch({
        type: "setPin",
        payload: {
          pin: chat.pin
        }
      });
    }
    const [listError, listResult] = yield (0, import_await_to_js.to)(
      client.models.Message.listMessageByChatIdAndCreatedAt(
        {
          chatId: eventId
        },
        {
          limit: 30,
          selectionSet: messageWithReplySelectionSet,
          sortDirection: "DESC"
        }
      )
    );
    if (listError !== null) {
      console.error(listError);
      return;
    }
    const { data: messages, nextToken } = listResult;
    console.debug({ messages, nextToken });
    dispatch({
      type: "addMessages",
      payload: { messages: messages.reverse(), nextToken }
    });
  });
}
function deleteMessage(dispatch, messageId) {
  return __async(this, null, function* () {
    const [error, result] = yield (0, import_await_to_js.to)(
      client.models.Message.delete({ id: messageId })
    );
    if (error !== null) {
      console.error(error);
      return;
    }
    const { data, errors } = result;
    console.debug({ data, errors });
    if (errors === void 0) {
      dispatch({
        type: "deleteMessage",
        payload: { message: { id: messageId } }
      });
    }
  });
}
function pinMessage(dispatch, messageId, eventId) {
  return __async(this, null, function* () {
    const [error, result] = yield (0, import_await_to_js.to)(
      client.models.Message.update(
        {
          id: messageId,
          pinId: eventId
        },
        { selectionSet: messageWithReplySelectionSet }
      )
    );
    if (error !== null || result.data === null) {
      console.error(error);
      return;
    }
    dispatch({
      type: "setPin",
      payload: {
        pin: result.data
      }
    });
  });
}
function unpinMessage(dispatch, messageId) {
  return __async(this, null, function* () {
    yield (0, import_await_to_js.to)(
      client.models.Message.update({
        id: messageId
      })
    );
    dispatch({
      type: "unpinMessage"
    });
  });
}
function getNextPage(dispatch, eventId, nextToken) {
  return __async(this, null, function* () {
    console.debug("getNextPage", { nextToken });
    if (nextToken === null)
      return;
    const [error, result] = yield (0, import_await_to_js.to)(
      client.models.Message.listMessageByChatIdAndCreatedAt(
        {
          chatId: eventId
        },
        {
          limit: 30,
          nextToken,
          selectionSet: messageWithReplySelectionSet,
          sortDirection: "DESC"
        }
      )
    );
    if (error !== null) {
      console.error(error);
      return;
    }
    const { data: messages, nextToken: newNextToken } = result;
    console.debug("nextPage", { messages, newNextToken });
    dispatch({
      type: "addMessages",
      payload: { messages: messages.reverse(), nextToken: newNextToken }
    });
  });
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ChatProvider,
  deleteMessage,
  getNextPage,
  messageSelectionSet,
  messageWithReplySelectionSet,
  pinMessage,
  sendMessage,
  unpinMessage,
  useAmplifyChat
});
